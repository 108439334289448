import { gozokiAiApi } from '../gozokiApi';

export const fetchAiVideoIds = async (storeId: string): Promise<string[]> => {
    const response = await gozokiAiApi.get(`/stores/${storeId}/video_id`);
    return response.data.video_id;
};

export const generateStreamUrl = async (
    storeId?: string | null,
    videoId?: string | null,
    socketToken?: string | null,
    streamUrl?: string | null
): Promise<string | null> => {
    if (!streamUrl || !storeId || !videoId) {
        return null;
    }
    return `${streamUrl}/stream/stores/${storeId}/video/${videoId}?token=${socketToken ?? ''}`;
};
