import { AuthContext, type AuthContextType } from '../utils/context/auth.context';
import { VisioContext, type VisioContextType } from '../utils/context/visio.context';
import { DailyProvider } from '@daily-co/daily-react';
import { DailyCall } from '@daily-co/daily-js';

import { type Right, hasOneRight, hasRights } from '@gozoki/tools';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ThemeProvider } from '@mui/material';
import { materialTheme } from '../utils/theme/material.theme';
import { setToken } from '../utils/api';
import { attachSocketToken, setAiSocketUrl, setStreamSocketUrl, useMe } from '@gozoki/api';
import { useNavigate } from 'react-router-dom';

export interface GlobalProviderProps {
    children: React.ReactNode;
}

const GlobalProvider = ({ children }: GlobalProviderProps) => {
    const me = useMe();
    const navigate = useNavigate();

    const [shouldStartCall, setShouldStartCall] = useState(false);
    const [kioskId, setKioskId] = useState<number | null>(null);
    const [storeId, setStoreId] = useState<number | null>(null);
    const [includeVideo, setIncludeVideo] = useState(false);
    const [isCallInProgress, setIsCallInProgress] = useState(false);
    const [callObject, setCallObject] = useState<null | DailyCall>(null);

    const askCall = useCallback(
        (newKioskId: number, newStoreId: number, newIncludeVideo: boolean) => {
            setKioskId(newKioskId);
            setStoreId(newStoreId);
            setShouldStartCall(!shouldStartCall);
            setIncludeVideo(newIncludeVideo);
        },
        [shouldStartCall]
    );

    const startCall = useCallback(() => {
        setShouldStartCall(false);
        setIsCallInProgress(true);
    }, []);

    const endCall = useCallback(() => {
        if (callObject) {
            callObject.leave();
            callObject.destroy();
            setCallObject(null);
        }
        setKioskId(null);
        setStoreId(null);
        setIsCallInProgress(false);
    }, [callObject]);

    useEffect(() => {
        if (me?.data?.socketToken) {
            attachSocketToken(me.data.socketToken);
        }
        if (me?.data?.socketAiUrl) {
            setAiSocketUrl(me.data.socketAiUrl);
        }
        if (me?.data?.socketStreamUrl) {
            setStreamSocketUrl(me.data.socketStreamUrl);
        }
    }, [me]);

    const logout = useCallback(async () => {
        await setToken(null);
        await me.refetch();
        navigate('/login'); // Navigate back to login page
    }, [me, navigate]);

    const authContextValue: AuthContextType = useMemo(() => {
        return {
            authenticated:
                !!me.data &&
                hasOneRight(me.data.adminRights, ['READER', 'STOCK_READER', 'ACCOUNTING_READER']),
            user: me.data ?? null,
            refetchUser: me.refetch,
            isLoading: me.isLoading,
            logout,
            hasRights: (rights: Right[]) => !!me.data && hasRights(me.data.adminRights, rights),
        };
    }, [me.data, me.refetch, me.isLoading, logout]);

    const visioContextValue: VisioContextType = useMemo(() => {
        return {
            shouldStartCall,
            kioskId,
            storeId,
            includeVideo,
            isCallInProgress,
            callObject,
            setCallObject,
            askCall,
            startCall,
            endCall,
        };
    }, [
        kioskId,
        shouldStartCall,
        storeId,
        includeVideo,
        isCallInProgress,
        callObject,
        setCallObject,
        endCall,
        startCall,
        askCall,
    ]);

    return (
        <AuthContext.Provider value={authContextValue}>
            <VisioContext.Provider value={visioContextValue}>
                <DailyProvider callObject={callObject}>
                    <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>
                </DailyProvider>
            </VisioContext.Provider>
        </AuthContext.Provider>
    );
};

export default GlobalProvider;
