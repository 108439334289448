import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import VideocamIcon from '@mui/icons-material/Videocam';
import { VisioContext } from '../../utils/context/visio.context';
import { useContext } from 'react';

interface InterventionButtonsProps {
    checkType: string;
    kioskId: number | null;
    storeId: number;
    disabled?: boolean;
}

const InterventionButtons = ({
    checkType,
    disabled = false,
    kioskId = null,
    storeId = 0,
}: InterventionButtonsProps) => {
    const { askCall } = useContext(VisioContext);
    const handleClick = (includeVideo: boolean) => {
        if (kioskId) {
            askCall(kioskId, storeId, includeVideo);
        }
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
                style={{
                    fontSize: '32px',
                    fontWeight: 400,
                    lineHeight: '40px',
                    fontFamily: 'BlockBerthold_regular',
                    textAlign: 'left',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                    marginBottom: '16px',
                }}
            >
                {checkType}
            </div>

            <Box display="flex" flexDirection="column" gap="8px">
                <Button
                    variant="contained"
                    sx={{
                        'backgroundColor': 'orange',
                        '&:hover': { backgroundColor: 'darkorange' },
                        '&:disabled': { backgroundColor: 'orange', opacity: '30%' },
                    }}
                    disabled={disabled || !kioskId}
                    onClick={() => handleClick(false)}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        gap="8px"
                        style={{
                            color: '#3E2500',
                            font: 'Avenir Next',
                        }}
                    >
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <KeyboardVoiceIcon fontSize="large" style={{ width: 24, height: 24 }} />
                            Intervention audio
                        </span>
                    </Box>
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        'backgroundColor': 'orange',
                        '&:hover': { backgroundColor: 'darkorange' },
                        '&:disabled': { backgroundColor: 'orange', opacity: '30%' },
                    }}
                    disabled={disabled || !kioskId}
                    onClick={() => handleClick(true)}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        gap="0px"
                        style={{
                            color: '#3E2500',
                            font: 'Avenir Next',
                        }}
                    >
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <VideocamIcon fontSize="large" style={{ width: 24, height: 24 }} />
                            Intervention audio & video
                        </span>
                    </Box>
                </Button>
            </Box>
        </div>
    );
};

export default InterventionButtons;
