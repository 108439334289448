import { useState, useEffect } from 'react';
import { Anomaly } from '@gozoki/api-types';
import '../../assets/styles/CameraFont.module.css';
import CloseIcon from '@mui/icons-material/Close';

interface AnomalyCardProps {
    anomaly: Anomaly;
    onDelete: (id: string) => void;
}

const AnomalyCard = ({ anomaly, onDelete }: AnomalyCardProps) => {
    const [timeElapsed, setTimeElapsed] = useState<string>('');
    const datetime = anomaly.timestamp;

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const eventDate = new Date(datetime);
            const elapsed = now.valueOf() - eventDate.valueOf();
            const seconds = Math.floor((elapsed / 1000) % 60);
            const minutes = Math.floor((elapsed / (1000 * 60)) % 60);
            const hours = Math.floor(elapsed / (1000 * 60 * 60));

            if (hours >= 1) {
                setTimeElapsed(`${hours}h`);
            } else if (minutes >= 1) {
                setTimeElapsed(`${minutes}min`);
            } else {
                setTimeElapsed(`${seconds} secs`);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [datetime]);
    const timeString = new Date(datetime).toLocaleTimeString();

    const iconStyle = {
        ...styles.icon,
        color: anomaly.color,
        backgroundColor: `rgba(${parseInt(anomaly.color.slice(1, 3), 16)}, ${parseInt(
            anomaly.color.slice(3, 5),
            16
        )}, ${parseInt(anomaly.color.slice(5, 7), 16)}, 0.1)`,
    };

    const titleStyle = {
        ...styles.title,
        color: anomaly.color,
    };

    return (
        <div style={styles.card}>
            <div style={styles.content}>
                <div style={iconStyle}>
                    <anomaly.icon style={{ width: '32px', height: '32px' }} />
                </div>
                <div style={styles.description}>
                    <h3 style={titleStyle}>{anomaly.name}</h3>
                    <p style={styles.paragraph}>
                        à {timeString} (il y a <strong>{timeElapsed}</strong>)
                    </p>
                </div>
                <a
                    href={anomaly.link === null ? anomaly.temporaryLink : anomaly.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.link}
                >
                    Voir l'extrait
                </a>
                <CloseIcon
                    fontSize="small"
                    onClick={() => onDelete(anomaly.id)}
                    sx={{
                        marginTop: '-42px',
                        marginRight: '5px',
                        color: 'red',
                        cursor: 'pointer',
                    }}
                />
            </div>
        </div>
    );
};

const AnomalyCards = ({
    anomalies,
    onDelete,
}: {
    anomalies: Anomaly[];
    onDelete: (id: string) => void;
}) => {
    return (
        <div>
            <div
                style={{
                    // width: '190px',
                    // height: '40px',
                    top: '104px',
                    fontFamily: 'BlockBerthold_regular',
                    fontSize: '32px',
                    fontWeight: 400,
                    lineHeight: '40px',
                    textAlign: 'left',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                }}
            >
                ALERTES
            </div>

            <div style={styles.listContainer}>
                {anomalies
                    .slice()
                    .sort(
                        (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
                    )
                    .map((anomaly) => (
                        <AnomalyCard key={anomaly.id} anomaly={anomaly} onDelete={onDelete} />
                    ))}
            </div>
        </div>
    );
};

const styles = {
    card: {
        width: '100%',
        height: '80px',
        borderRadius: '8px',
        border: '1px solid #E2E8F0',
        backgroundColor: '#FFFFFF',
        display: 'flex',
    },
    description: {
        marginLeft: '10px',
        textIndent: '0',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '4px',
    },
    title: {
        margin: 0,
    },
    paragraph: {
        margin: 0,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
    icon: {
        fontSize: '32px',
        marginRight: '8px',
        width: '64px',
        height: '64px',
        backgroundColor: 'blue',
        borderRadius: '4px',
        marginLeft: '8px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    link: {
        fontFamily: 'Avenir Next',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19.12px',
        textAlign: 'right' as const,
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid' as 'solid' | 'dotted' | 'dashed' | 'wavy',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none' as 'none' | 'auto',
        color: '#1E2637',
        marginRight: '15px',
        marginLeft: 'auto',
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'flex-start',
        gap: '8px',
    },
};

export default AnomalyCards;
