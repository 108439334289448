import { useEffect, useState } from 'react';

import { gozokiAiWebSocketManager } from '../gozokiApi';
import { useQueryClient } from '@tanstack/react-query';

export const useLiveAiArticles = (storeId: string) => {
    const queryClient = useQueryClient();
    const [socket, setSocket] = useState<WebSocket | null>(null);

    useEffect(() => {
        let errorCount = 0;
        const ws = gozokiAiWebSocketManager.connect(`stores/${storeId}/ws/rfid`);
        setSocket(ws);

        const articlesQueryKey = ['ai-articles', storeId];
        const consistencyQueryKey = ['ai-consistency', storeId];

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data) {
                queryClient.setQueryData(consistencyQueryKey, () => data.consistency);
                queryClient.setQueryData(articlesQueryKey, () => data.content);
            }
        };

        ws.onerror = (error) => {
            errorCount++;
            if (errorCount > 5) {
                console.error('Multiple webSocket errors', error);
            }
        };

        return () => {
            ws.close();
        };
    }, [queryClient, storeId]);

    return { socket };
};
