/* eslint-disable @typescript-eslint/no-explicit-any */
export class WebSocketManager {
    private baseUrl: string | null = null;
    private sockets: Map<string, WebSocket> = new Map();
    private authToken: string | null = null;
    private useAuthMessage: boolean;

    constructor(useAuthMessage = false) {
        this.useAuthMessage = useAuthMessage;
    }

    removeLastSlashIfNeeded(url: string) {
        if (url.endsWith('/')) {
            return url.slice(0, -1);
        }
        return url;
    }

    // Définnir l'url de base
    setBaseUrl(url: string) {
        this.baseUrl = url;
    }

    // Définir le token à utiliser pour toutes les connexions
    attachToken(token: string) {
        this.authToken = token;
    }

    // Créer une connexion WebSocket
    connect(path: string, queries?: Record<string, string>, port?: number): WebSocket {
        const allQueries: Record<string, string> = {
            ...queries,
            bearer: this.authToken ?? '',
        };

        const fullUrl = `${this.removeLastSlashIfNeeded(this.baseUrl ?? '')}${
            port && port > 0 ? `:${port}` : ''
        }/${path}?${new URLSearchParams(allQueries)}`;

        if (this.sockets.has(fullUrl) && this.sockets.get(fullUrl)?.readyState === WebSocket.OPEN) {
            // biome-ignore lint/style/noNonNullAssertion:
            return this.sockets.get(fullUrl)!;
        }

        const socket = new WebSocket(fullUrl);

        socket.onopen = () => {
            if (this.useAuthMessage && this.authToken) {
                socket.send(JSON.stringify({ type: 'auth', bearer: this.authToken }));
            }
        };

        socket.onerror = (error) => {
            console.error(`[WebSocket] Erreur sur ${fullUrl}`, error);
        };

        socket.onclose = () => {
            this.sockets.delete(fullUrl);
            setTimeout(() => this.connect(path), 300000000); // Reconnexion automatique après 3s
        };

        this.sockets.set(fullUrl, socket);
        return socket;
    }

    // Envoyer un message à un WebSocket
    // biome-ignore lint/suspicious/noExplicitAny:
    send(path: string, data: any) {
        const socket = this.sockets.get(`${this.baseUrl}${path}`);

        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(data));
        } else {
            console.error(`[WebSocket] Impossible d'envoyer, socket ${path} non ouverte`);
        }
    }

    // Déconnecter un WebSocket spécifique
    disconnect(path: string) {
        const fullUrl = `${this.baseUrl}${path}`;
        const socket = this.sockets.get(fullUrl);

        if (socket) {
            socket.close();
            this.sockets.delete(fullUrl);
        }
    }

    // Déconnecter toutes les connexions WebSocket
    disconnectAll() {
        // biome-ignore lint/complexity/noForEach:
        this.sockets.forEach((socket) => {
            socket.close();
        });
        this.sockets.clear();
    }
}
