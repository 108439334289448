import { fetchCameraStores } from '../queries/camera-stores.queries';
import { useQuery } from '@tanstack/react-query';

export const useCameraStores = () => {
    return useQuery(['ai-stores', 'cameras'], () => fetchCameraStores(), {
        onError: (error) => {
            console.error('Erreur lors de la récupération des magasins:', error);
        },
        refetchOnWindowFocus: false,
    });
};
