import ExcelJS from 'exceljs';

type DataRow<T extends Record<string, string | number>> = T;

export interface XlsxOptions<T extends Record<string, string | number>> {
    data: DataRow<T>[];
    columns: Record<keyof T, string>;
    label: string;
}

export const downloadDataXlsx = async <T extends Record<string, string | number>>({
    data,
    columns,
    label,
}: XlsxOptions<T>): Promise<void> => {
    if (data.length === 0) {
        console.warn('No data to export.');
        return;
    }

    const keys = Object.keys(columns) as Array<keyof T>;
    const dataKeys = Object.keys(data[0]) as Array<keyof T>;

    if (keys.length !== dataKeys.length || !keys.every((key) => dataKeys.includes(key))) {
        throw new Error('Columns keys must match the keys in data objects.');
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(label);

    // Ajouter l'en-tête
    worksheet.addRow(keys.map((key) => columns[key]));

    // Ajouter les données
    data.forEach((row) => {
        worksheet.addRow(keys.map((key) => row[key]));
    });

    // Appliquer un formatage basique (mise en gras de l'en-tête, auto-size des colonnes)
    worksheet.getRow(1).font = { bold: true };
    worksheet.columns = keys.map((key) => ({
        header: columns[key],
        key: key as string,
        width: 15,
    }));

    // Générer le fichier xlsx
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);

    // Création du lien et téléchargement
    const a = document.createElement('a');
    a.href = url;
    a.download = `${label}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};
