import { getToken } from '../internal/tokenCallback';
import { me } from '../queries/login.queries';
import { useQuery } from '@tanstack/react-query';

export const useMe = () => {
    return useQuery(
        ['me'],
        async () => {
            const token = await getToken();
            // user not logged in yet
            if (token === '') {
                return null;
            }
            try {
                const response = await me();
                return response.user;
            } catch (e) {
                return null; // No user for current token
            }
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};
