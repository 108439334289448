import type { Anomaly } from '@gozoki/api-types';
import { fetchAnomalies } from '../queries/anomalies.queries';
import { useQuery } from '@tanstack/react-query';

export const useAnomalies = (storeId: string) => {
    return useQuery<Anomaly[], Error>(
        ['anomalies', storeId],
        () => {
            if (Number.isNaN(storeId)) return [];
            return fetchAnomalies(storeId);
        },
        {
            initialData: [] as Anomaly[],
        }
    );
};
