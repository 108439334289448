import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchAskVisio } from '../queries/visio.queries';
import { VisioLink } from '@gozoki/api-types';

export const useVisio = (
    kioskId: number | null,
    includeVideo: boolean,
    shouldStartCall: boolean
): UseQueryResult<VisioLink> => {
    return useQuery(['visio', kioskId], () => fetchAskVisio(kioskId as number, includeVideo), {
        enabled: shouldStartCall && !!kioskId !== null,
    });
};
