import type { Anomaly, AnomalyAPIResponse } from '@gozoki/api-types';
import { createAnomaly, createAnomalyLink } from '../queries/anomalies.queries';
import { useEffect, useState } from 'react';

import { gozokiAiWebSocketManager } from '../gozokiApi';
import { useQueryClient } from '@tanstack/react-query';

export const useLiveAnomalies = (storeId: string) => {
    const queryClient = useQueryClient();
    const [socket, setSocket] = useState<WebSocket | null>(null);

    useEffect(() => {
        let errorCount = 0;
        const ws = gozokiAiWebSocketManager.connect(`stores/${storeId}/ws/alerts`);
        setSocket(ws);

        const queryKey = ['anomalies', storeId];

        ws.onmessage = (event) => {
            const rawData = JSON.parse(event.data);
            const contentType = rawData.type;
            if (contentType === 'POST') {
                const rawAnomaly: AnomalyAPIResponse = rawData.content;
                const newAnomaly = createAnomaly(
                    Number.parseInt(rawAnomaly.anomalyTypeId, 10),
                    createAnomalyLink(storeId, rawAnomaly.videoId, rawAnomaly.startTime),
                    rawAnomaly.link,
                    rawAnomaly.id,
                    rawAnomaly.timestamp
                );

                if (newAnomaly) {
                    queryClient.setQueryData(queryKey, (prevAnomalies: Anomaly[] = []) => [
                        ...prevAnomalies,
                        newAnomaly,
                    ]);
                }
            } else if (contentType === 'PUT') {
                const updatedData = rawData.content;
                queryClient.setQueryData(queryKey, (prevAnomalies: Anomaly[] = []) =>
                    prevAnomalies.map((anomaly) =>
                        anomaly.id === updatedData.id ? { ...anomaly, ...updatedData } : anomaly
                    )
                );
            }
        };

        ws.onerror = (error) => {
            errorCount++;
            if (errorCount > 5) {
                console.error('Multiple webSocket errors', error);
            }
        };

        return () => {
            ws.close();
        };
    }, [queryClient, storeId]);

    return { socket };
};
