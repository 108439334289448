import type { DailyDashboardKpi, DailyStockKpi, DayOfWeek } from '@gozoki/api-types';

import { gozokiApiWithAuth } from '../gozokiApi';

// Request an authentication token with credentials
export const fetchAllDailysKpis = async () => {
    const response = await gozokiApiWithAuth.get<DailyDashboardKpi>('/stores/get-all-kpis');
    return response.data;
};

export const fetchAllStockKpis = async () => {
    const response = await gozokiApiWithAuth.get<DailyStockKpi>('/stores/get-all-stock-kpis');
    return response.data;
};

export const fetchAccountingKpis = async (date: string) => {
    const response = await gozokiApiWithAuth.get(`/stores/get-accounting-kpis/${date}`); // 'AAAA-MM-DD'
    return response.data;
};

export const fetchHoursData = async (
    days: DayOfWeek[],
    storeIds: number[],
    plage: 'AM' | 'PM' | 'DAY',
    dateStart: Date,
    dateEnd: Date
) => {
    if (days.length === 0 || storeIds.length === 0) return [];
    const response = await gozokiApiWithAuth.post('/stores/get-hours-data', {
        days,
        storeIds,
        plage,
        dateStart: dateStart.toDateString(),
        dateEnd: dateEnd.toDateString(),
    });
    return response.data;
};

export const fetchTimeSpentData = async (
    days: DayOfWeek[],
    storeIds: number[],
    plage: 'AM' | 'PM' | 'DAY',
    dateStart: Date,
    dateEnd: Date
) => {
    if (days.length === 0 || storeIds.length === 0) return [];
    const response = await gozokiApiWithAuth.post('/stores/get-time-spent-data', {
        days,
        storeIds,
        plage,
        dateStart: dateStart.toLocaleString(),
        dateEnd: dateEnd.toDateString(),
    });
    return response.data;
};

export const fetchAccountingWarnings = async () => {
    const response = await gozokiApiWithAuth.get('/stores/get-accounting-warnings');
    return response.data;
};

export const fetchAverageTimeSpent = async (
    currentMode: string,
    startDate: Date,
    endDate: Date
) => {
    const response = await gozokiApiWithAuth.get(
        `/time-spent/get-average-time-spent?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&mode=${currentMode}`
    );
    return response.data;
};
