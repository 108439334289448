import { Button, Paper, Stack, TablePagination, TextField, Typography } from '@mui/material';
import { DateStateProps, FourDatePicker } from '../../utils/dashboard/fourDatePicker';
import {
    exportProductsMinimumStockToXlsx,
    useProducts,
    useStoreStock,
    useStoresKpis,
} from '@gozoki/api';
import { useCallback, useMemo, useState } from 'react';

import { CategoryPicker } from '../../utils/dashboard/categoryPicker';
import { DAILY_SALES_ROUTES } from '../../utils/constants/routes.constants';
import { DailyMinsAndSold } from '@gozoki/api-types';
import { DailySalesGrid } from './DailySalesGrid';
import ImportExportIcon from '../../components/icons/ImportExportIcon';
import Page from '../../components/Page';
import { StoresPicker } from '../../utils/dashboard/storesPicker';
import { fonts } from '../../utils/theme/fonts.theme';
import { includesLowerCase } from '@gozoki/tools';
import { timeRanges } from '../../utils/dashboard/timeRanges';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRights } from '../../utils/hooks';
import { useToast } from '../../components/communication/Toast';

export const DailySalesPage = () => {
    const navigate = useNavigate();
    const isUserAdmin = useRights(['ADMIN']);
    const [Toast, showToast] = useToast();

    const [currentStore, setCurrentStore] = useState<number>(3);
    const [currentCategory, setCurrentCategory] = useState<number>(0);
    const { data: products } = useProducts();
    const { data: allKpis } = useStoresKpis();
    const { data: stock, refetch: refetchStock } = useStoreStock(currentStore);
    const [search, setSearch] = useState('');

    const today = new Date();
    const oneMonthBefore = new Date();
    oneMonthBefore.setMonth(today.getMonth() - 1);

    const [currentTimeRange, setCurrentTimeRange] = useState<number>(-1);
    const [currentDateStart, setCurrentDateStart] = useState<Date>(oneMonthBefore);
    const [currentDateEnd, setCurrentDateEnd] = useState<Date>(today);

    const allProducts = useMemo(() => {
        return products ? products.filter((p) => p.isActive) : [];
    }, [products]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setRowsPerPage(Number.parseInt(event.target.value, 10));
            setPage(0);
        },
        [setRowsPerPage, setPage]
    );

    const currentStoreKpis = useMemo(() => {
        if (!allKpis) return [];
        return allKpis?.filter(
            (kpi) =>
                currentStore === kpi.storeId &&
                new Date(kpi.date).getTime() >= currentDateStart.getTime() &&
                new Date(kpi.date).getTime() <= currentDateEnd.getTime()
        );
    }, [allKpis, currentStore, currentDateStart, currentDateEnd]);

    const dailyMinsAndSold = useMemo(() => {
        const daily: DailyMinsAndSold = {};
        stock?.rows.forEach((row) => {
            daily[row.reference] = {
                minStock: row.minimumStock,
                weekEndMinimumStock: row.weekEndMinimumStock,
                soldMap: {},
                minMap: row.dailyMinStocksMap,
            };
        });

        currentStoreKpis.forEach((kpi) => {
            const jsDay = new Date(kpi.date).getDay(); // 0 = dimanche, 6 = samedi !!
            const day = jsDay === 0 ? 6 : jsDay - 1;

            allProducts.forEach(({ reference }) => {
                if (kpi.productsSoldMap[reference]) {
                    if (!daily[reference]) {
                        daily[reference] = { soldMap: {}, minMap: {} };
                    }
                    if (!daily[reference].soldMap[day]) {
                        daily[reference].soldMap[day] = { totalCount: 0, totalDays: 0 };
                    }
                    daily[reference].soldMap[day].totalCount +=
                        kpi.productsSoldMap[reference].count;
                    daily[reference].soldMap[day].totalDays += 1;
                }
            });
        });

        return daily;
    }, [currentStoreKpis, stock, allProducts]);

    const currentCategoryProducts = useMemo(() => {
        if (!allProducts) return [];
        return allProducts.filter((product) => {
            const inCategory =
                !currentCategory || product.categories.some((c) => c.id === currentCategory);

            return inCategory;
        });
    }, [currentCategory, allProducts]);

    const currentSearchProducts = useMemo(() => {
        if (!currentCategoryProducts) return [];
        return currentCategoryProducts.filter((product) => {
            return includesLowerCase(product.label, search);
        });
    }, [currentCategoryProducts, search]);

    const productsPaginated = useMemo(() => {
        return currentSearchProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [currentSearchProducts, page, rowsPerPage]);

    const { mutate: downloadProductMinimumStock } = useMutation(exportProductsMinimumStockToXlsx, {
        onSuccess: (response) => {
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `product_minimum_stock.xlsx`;
            link.click();
            URL.revokeObjectURL(url);
        },
        onError: async (error: { message: string; response: { data: Blob } }) => {
            console.error(error);
            if (error?.response?.data?.size) {
                // this is a blob
                const text = await error.response.data.text();
                const json: { message: string } = JSON.parse(text);
                showToast({
                    severity: 'error',
                    message: json.message as string,
                });
            } else {
                showToast({
                    severity: 'error',
                    message: error.message as string,
                });
            }
        },
    });

    const dateState: DateStateProps = useMemo(() => {
        return {
            timeRanges: {
                all: timeRanges.filter((t) => t.value > 1),
                current: currentTimeRange,
                set: setCurrentTimeRange,
            },
            currentDate: {
                start: currentDateStart,
                setStart: setCurrentDateStart,
                end: currentDateEnd,
                setEnd: setCurrentDateEnd,
            },
        };
    }, [currentTimeRange, currentDateStart, currentDateEnd]);

    const handleChangeCategory = useCallback(
        (value: number) => {
            setPage(0);
            setCurrentCategory(value);
        },
        [setPage, setCurrentCategory]
    );

    return (
        <Page sx={fonts.roboto}>
            <Toast />
            <div style={{ display: 'flex', paddingLeft: 20, paddingBottom: 20 }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Ventes journalières
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => downloadProductMinimumStock()}
                    disabled={!isUserAdmin}
                >
                    <ImportExportIcon />
                    Exporter les stocks minimum
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        marginLeft: '16px',
                        marginRight: '16px',
                    }}
                    onClick={() => navigate(DAILY_SALES_ROUTES.IMPORT)}
                    disabled={!isUserAdmin}
                >
                    <ImportExportIcon />
                    Importer
                </Button>
            </div>
            <Stack gap={1} p={1} flexDirection="row">
                <FourDatePicker dateState={dateState} />
                <StoresPicker
                    setUniqueStore={setCurrentStore}
                    allowAllStores={false}
                    defaultStore={currentStore}
                />
                <CategoryPicker setCurrentCategory={handleChangeCategory} />
            </Stack>
            <Paper
                sx={{
                    marginTop: '16px',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <TextField
                        variant="outlined"
                        label="Recherche"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                    />
                    <div style={{ flexGrow: 1 }} />
                </div>
                <DailySalesGrid
                    storeId={currentStore}
                    dailyMinsAndSold={dailyMinsAndSold}
                    products={productsPaginated}
                    refetch={refetchStock}
                />
                <TablePagination
                    component="div"
                    count={currentSearchProducts.length}
                    page={page}
                    onPageChange={(_, p) => setPage(p)}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Page>
    );
};
