import { type Anomaly, type AnomalyAPIResponse } from '@gozoki/api-types';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import ElectricScooterIcon from '@mui/icons-material/ElectricScooter';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import PetsIcon from '@mui/icons-material/Pets';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { red } from '@mui/material/colors';

import { gozokiAiApi } from '../gozokiApi';

export function createAnomalyLink(storeId: string, videoId: string, startTime: string): string {
    return `https://ia-video-stream-staging.lucie-la-cabane-autonome.fr/stream/stores/${storeId}/video/${videoId}&starttime=${startTime}`;
    // TODO : hide the AI backend link
}

export function createAnomaly(
    anomalyTypeId: number,
    temporaryLink: string,
    link: string | null,
    id: string,
    timestamp: string
): Anomaly {
    const anomaly = ANOMALY_DETAILS[anomalyTypeId] || UNKNOWN_ANOMALY;
    return {
        name: anomaly.name,
        icon: anomaly.icon,
        color: anomaly.color,
        link,
        temporaryLink,
        id,
        anomalyTypeId: anomalyTypeId.toString(),
        timestamp,
    };
}

export const fetchAnomalies = async (storeId: string) => {
    const response = await gozokiAiApi.get<{ alerts: AnomalyAPIResponse[] }>(
        `/stores/${storeId}/alerts`
    );
    if (Array.isArray(response.data?.alerts)) {
        return response.data.alerts.map((a) => {
            return createAnomaly(
                Number.parseInt(a.anomalyTypeId, 10),
                createAnomalyLink(storeId, a.videoId, a.startTime),
                a.link,
                a.id,
                a.timestamp
            );
        });
    }
    return [];
};

const ANOMALY_RED = red[600];
const ANOMALY_GRAY = '#1E2637';

export const ANOMALY_DETAILS = [
    { name: 'Blocage en caisse', icon: BlockIcon, color: ANOMALY_RED },
    { name: 'Client allongé', icon: LocalHospitalIcon, color: ANOMALY_GRAY },
    { name: 'Trop de monde', icon: GroupsIcon, color: ANOMALY_GRAY },
    { name: 'Geste Suspicieux', icon: RemoveRedEyeIcon, color: ANOMALY_GRAY },
    { name: 'Agitation', icon: RemoveRedEyeIcon, color: ANOMALY_GRAY },
    { name: 'Incohérence panier', icon: ShoppingCartIcon, color: ANOMALY_RED },
    { name: 'Chien', icon: PetsIcon, color: ANOMALY_GRAY },
    { name: 'Trotinette électrique', icon: ElectricScooterIcon, color: ANOMALY_GRAY },
    { name: 'Vélo', icon: PedalBikeIcon, color: ANOMALY_GRAY },
    { name: 'Degradation', icon: DeleteIcon, color: ANOMALY_GRAY },
    { name: "Blocage à l'entrée", icon: DoorFrontIcon, color: ANOMALY_GRAY },
];

export const UNKNOWN_ANOMALY = {
    name: 'Anomalie inconnue',
    icon: QuestionMarkIcon,
    color: ANOMALY_GRAY,
};
