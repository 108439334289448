import { useCallback, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { type Category, type DailyMinsAndSold } from '@gozoki/api-types';
import { DailySalesGridRow } from './DailySalesGridRow';

interface DailySalesGridProps {
    storeId: number;
    dailyMinsAndSold: DailyMinsAndSold;
    products: { categories: Category[]; label: string; reference: string }[];
    refetch: () => void;
}

const columns = [
    'Produit',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
    'Moyenne (stock min par défaut)',
    'Weekend (Samedi + Dimanche)',
];

export const DailySalesGrid = (props: DailySalesGridProps) => {
    const { dailyMinsAndSold, products, storeId, refetch } = props;
    const [editCell, setEditCell] = useState<string | null>(null);

    const handleModify = useCallback((reference: string) => {
        setEditCell(reference);
    }, []);

    const handleSave = useCallback(() => {
        if (!editCell) return;
        refetch();
        setEditCell(null);
    }, [editCell, refetch]);

    const getDailyAverage = useCallback(
        (reference: string, day: number) => {
            if (!dailyMinsAndSold[reference.toString()]) {
                return 0;
            }
            if (!dailyMinsAndSold[reference.toString()].soldMap[day]) {
                return 0;
            }

            if (dailyMinsAndSold[reference.toString()].soldMap[day].totalDays === undefined) {
                return 0;
            }
            if (dailyMinsAndSold[reference.toString()].soldMap[day].totalCount === undefined) {
                return 0;
            }
            return (
                Math.round(
                    (100 * dailyMinsAndSold[reference.toString()].soldMap[day].totalCount) /
                        dailyMinsAndSold[reference.toString()].soldMap[day].totalDays
                ) / 100
            );
        },
        [dailyMinsAndSold]
    );

    const rows = useMemo(() => {
        const productsFiltered = products.filter(
            (product) => !!dailyMinsAndSold[product.reference]
        );
        return productsFiltered.map(({ label, reference }) => {
            const { minStock } = dailyMinsAndSold[reference];
            const { weekEndMinimumStock } = dailyMinsAndSold[reference];
            const minStockValues = {
                minimumStock: minStock || 0,
                weekEndMinimumStock: weekEndMinimumStock || 0,
                mondayMinimumStock: dailyMinsAndSold[reference].minMap.monday,
                tuesdayMinimumStock: dailyMinsAndSold[reference].minMap.tuesday,
                wednesdayMinimumStock: dailyMinsAndSold[reference].minMap.wednesday,
                thursdayMinimumStock: dailyMinsAndSold[reference].minMap.thursday,
                fridayMinimumStock: dailyMinsAndSold[reference].minMap.friday,
                saturdayMinimumStock: dailyMinsAndSold[reference].minMap.saturday,
                sundayMinimumStock: dailyMinsAndSold[reference].minMap.sunday,
            };

            const avgValues: number[] = [0, 1, 2, 3, 4, 5, 6].map((day) =>
                getDailyAverage(reference, day)
            );

            const average =
                Math.round((avgValues.reduce((a, b) => a + b, 0) * 100) / avgValues.length) / 100;

            const weekEndAverage = Math.round((avgValues[5] + avgValues[6]) * 100) / 100;

            avgValues.push(average);
            avgValues.push(weekEndAverage);

            return { minStockValues, avgValues, label, reference };
        });
    }, [products, dailyMinsAndSold, getDailyAverage]);

    return (
        <Stack gap={1} p={1}>
            <Stack flexDirection="row" gap={1} borderBottom="1px solid lightGray" pb={2}>
                {columns.map((column, index) => {
                    return (
                        <Typography
                            key={`column-${column}-${
                                // biome-ignore lint/suspicious/noArrayIndexKey: index is used as a key
                                index
                            }`}
                            flex={column === 'Produit' ? 2 : 1}
                            px={column === 'Produit' ? 1 : 0}
                        >
                            {column}
                        </Typography>
                    );
                })}
                <Stack flex={2} />
            </Stack>
            {rows.map(({ avgValues, minStockValues, label, reference }) => (
                <DailySalesGridRow
                    key={`row-${reference}`}
                    storeId={storeId}
                    productReference={reference}
                    avgValues={avgValues}
                    minStockValues={minStockValues}
                    label={label}
                    edit={editCell === reference}
                    disabled={editCell !== null && editCell !== reference}
                    onModify={handleModify}
                    onSave={handleSave}
                />
            ))}
        </Stack>
    );
};
