import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { Store } from '@gozoki/api-types';
import { useStores } from '@gozoki/api';

export interface StoresPickerProps {
    setCurrentStores?: (value: number[]) => void;
    setUniqueStore?: (value: number) => void;
    onlyBoutiques?: boolean;
    defaultStore?: number;
    onlyPlatforms?: boolean;
    storesToKeep?: string[];
    allowAllStores?: boolean;
    usedStores?: Store['id'][];
}

export const StoresPicker = ({
    setCurrentStores,
    setUniqueStore,
    onlyBoutiques,
    defaultStore,
    onlyPlatforms,
    storesToKeep,
    allowAllStores,
    usedStores,
}: StoresPickerProps) => {
    const { data: stores } = useStores();

    const [isInitialized, setIsInitialized] = useState(false);
    const allStoresIds = useMemo(() => {
        if (usedStores) {
            return stores?.filter((s) => usedStores.includes(s.id)).map((s) => s.id);
        }
        return stores
            ?.filter((store) => (onlyBoutiques ? store.storeType === 'BOUTIQUE' : true))
            .filter((store) => (onlyPlatforms ? store.storeType === 'WAREHOUSE' : true))
            .filter((store) =>
                storesToKeep ? storesToKeep.some((s) => s === store.checkpointStoreRef) : true
            )
            .map((store) => store.id);
    }, [onlyBoutiques, onlyPlatforms, stores, storesToKeep, usedStores]);

    const realDefaultStore = useMemo(() => {
        if (defaultStore) return defaultStore;
        if (allStoresIds?.length) return allStoresIds[0];
        return null;
    }, [allStoresIds, defaultStore]);

    useEffect(() => {
        if (isInitialized) return;
        if (!allStoresIds || allStoresIds.length === 0) return;
        if (setCurrentStores) setCurrentStores(allStoresIds ?? []);
        if (setUniqueStore && realDefaultStore) setUniqueStore(realDefaultStore);
        setIsInitialized(true);
    }, [allStoresIds, realDefaultStore, setCurrentStores, setUniqueStore, isInitialized]);

    const storeSelectOptions = useMemo(() => {
        let options: { label: string; value: number }[] | undefined;
        if (usedStores) {
            options = stores
                ?.filter((s) => usedStores.includes(s.id))
                .map((store) => ({
                    label: store.label,
                    value: store.id,
                }));
        } else {
            options = stores
                ?.filter((store) => (onlyBoutiques ? store.storeType === 'BOUTIQUE' : true))
                .filter((store) => (onlyPlatforms ? store.storeType === 'WAREHOUSE' : true))
                .filter((store) =>
                    storesToKeep ? storesToKeep.some((s) => s === store.checkpointStoreRef) : true
                )
                .map((store) => ({
                    label: store.label,
                    value: store.id,
                }));
        }
        if (!setUniqueStore || allowAllStores) {
            options?.push({ label: 'Toutes les boutiques', value: 0 });
        }
        return options;
    }, [
        allowAllStores,
        onlyBoutiques,
        onlyPlatforms,
        setUniqueStore,
        stores,
        storesToKeep,
        usedStores,
    ]);

    return (
        <div style={{ display: 'flex', flex: 2, margin: 6 }}>
            <FormControl fullWidth>
                <InputLabel>Boutique</InputLabel>
                <Select
                    label="Boutique"
                    defaultValue={defaultStore ?? storeSelectOptions?.[0]?.value}
                    onChange={(e) => {
                        if (setUniqueStore) setUniqueStore(e.target.value as number);
                        if (setCurrentStores) {
                            setCurrentStores(
                                e.target.value === 0
                                    ? allStoresIds ?? []
                                    : [e.target.value as number]
                            );
                        }
                    }}
                >
                    {storeSelectOptions?.map((store) => (
                        <MenuItem key={store.value} value={store.value}>
                            {store.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
