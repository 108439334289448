import { useContext, useEffect, useCallback } from 'react';
import { VisioContext } from '../../utils/context/visio.context';
import Daily from '@daily-co/daily-js';
import { DailyProvider } from '@daily-co/daily-react';
import { useVisio } from '@gozoki/api';
import VideoCall from './VideoCall';

const VisioComponent = () => {
    const {
        shouldStartCall,
        startCall,
        kioskId,
        includeVideo,
        endCall,
        callObject,
        setCallObject,
    } = useContext(VisioContext);
    const { data: visio } = useVisio(kioskId, includeVideo, shouldStartCall);
    const joinCall = useCallback(() => {
        if (!visio?.url || !visio?.token) return;
        const newCallObject = Daily.createCallObject();
        setCallObject(newCallObject);
        newCallObject.join({ url: visio?.url, token: visio?.token, startVideoOff: !includeVideo });
    }, [visio?.url, visio?.token, setCallObject, includeVideo]);

    useEffect(() => {
        if (shouldStartCall && visio?.url && visio?.token) {
            startCall();
            joinCall();
        }
    }, [shouldStartCall, startCall, kioskId, joinCall, visio]);

    return (
        <div
            style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: 1000,
                backgroundColor: 'white',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            }}
        >
            <DailyProvider callObject={callObject}>
                <VideoCall leaveCall={endCall} cameraOn={includeVideo} />
            </DailyProvider>
        </div>
    );
};

export default VisioComponent;
