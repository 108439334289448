import { Kiosk } from '@gozoki/api-types';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchCheckinKiosks } from '../queries/checkinKiosk.queries';

export const useCheckinKiosks = (storeId: number): UseQueryResult<Kiosk[], unknown> => {
    return useQuery(['checkin', storeId], () => {
        return fetchCheckinKiosks(storeId);
    });
};

export const useCheckinKiosk = (storeId: number): UseQueryResult<Kiosk[], unknown> => {
    return useQuery(['checkin', storeId], () => {
        return fetchCheckinKiosks(storeId);
    });
};
