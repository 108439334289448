import { gozokiApiWithAuth } from '../gozokiApi';

interface VisioLink {
    url: string;
    message: string;
    token: string;
}

export const fetchAskVisio = async (kioskId: number, includeVideo: boolean) => {
    const res = await gozokiApiWithAuth.post<VisioLink>(`/visio/start/${kioskId}`, {
        includeVideo,
    });
    return res.data;
};
