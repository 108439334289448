import { Box, Button, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import {
    useInventory,
    useStore,
    useUpdateInventoryStatus,
    useUpdateUnScannableProducts,
} from '@gozoki/api';

import { DashboardCard } from '../../utils/dashboard/dashboardCards';
import DoneIcon from '@mui/icons-material/Done';
import { InventoryDetails } from './InventoryDetails';
import { InventoryGrid } from './InventoryGrid';
import Page from '../../components/Page';
import { downloadDataXlsx } from '../../utils/excel/downloadDataCsv';
import { fonts } from '../../utils/theme/fonts.theme';
import { frenchFormat } from '@gozoki/tools';
import { useParams } from 'react-router-dom';

export interface InventoryProductRow extends Record<string, string | number> {
    id: number;
    theoricalCount: number;
    inventoryCount: number;
    unscannedCount: number;
    delta: number;
    reference: string;
    label: string;
    price: number;
}

const VALIDATE_BUTTON_STATE = {
    pending: { text: "Valider l'inventaire", disabled: false },
    validated: { text: 'Validé', disabled: true },
    canceled: { text: 'Annulé', disabled: true },
};

const CANCEL_BUTTON_STATE = {
    validated: null,
    pending: { text: "Annuler l'inventaire", disabled: false },
    canceled: null,
};

export const InventoryDetailsPage = () => {
    const { id, storeId: storeId_ } = useParams();
    const inventoryId = useMemo(() => Number.parseInt(id ?? '', 10), [id]);
    const storeId = useMemo(() => Number.parseInt(storeId_ ?? '', 10), [storeId_]);

    const { data: inventoryDetail, isLoading, refetch: fetchInventory } = useInventory(inventoryId);
    const [updatedInventoryDetail, setUpdatedInventoryDetail] = useState<boolean>(false);
    const { data: store } = useStore(storeId);
    const { mutate: updateUnScannableProducts } = useUpdateUnScannableProducts();
    const { mutate: updateInventoryStatus } = useUpdateInventoryStatus();
    const incomingStockTotal = inventoryDetail?.incomingStock?.length ?? 0;
    const currentStockTotal = inventoryDetail?.currentStock?.length ?? 0;

    const productRows = useMemo(() => {
        if (!inventoryDetail?.incomingStock) return [];
        const incomingProductsAcc: InventoryProductRow[] = [];

        const incoming = inventoryDetail?.incomingStock?.reduce((acc, article) => {
            const product = acc.find((p) => p.id === article.product.id);

            if (product) {
                return acc.map((p) => {
                    if (p.id === article.product.id) {
                        p.inventoryCount += 1;
                        p.delta += 1;
                    }
                    return p;
                });
            }
            return acc.concat({
                id: article.product.id,
                inventoryCount: 1,
                theoricalCount: 0,
                unscannedCount: 0,
                delta: +1,
                label: article.product.label,
                price: article.product.price,
                reference: article.product.reference,
            });
        }, incomingProductsAcc);

        const incomingCurrent = inventoryDetail?.currentStock
            ? inventoryDetail?.currentStock?.reduce((acc, article) => {
                  const product = acc.find((p) => p.id === article.product.id);
                  if (product) {
                      return acc.map((p) => {
                          if (p.id === article.product.id) {
                              p.theoricalCount += 1;
                              p.delta -= 1;
                          }
                          return p;
                      });
                  }
                  return acc.concat({
                      id: article.product.id,
                      inventoryCount: 0,
                      theoricalCount: 1,
                      unscannedCount: 0,
                      delta: -1,
                      label: article.product.label,
                      price: article.product.price,
                      reference: article.product.reference,
                  });
              }, incoming)
            : incoming;

        if (!inventoryDetail?.unScannableProducts?.unScannableProducts) return incomingCurrent;

        const incomingOutgoingUnscanned =
            inventoryDetail?.unScannableProducts.unScannableProducts?.reduce(
                (acc, unScannableProduct) => {
                    return acc.map((p) => {
                        if (p.reference === unScannableProduct.productReference) {
                            p.unscannedCount = unScannableProduct.count;
                            p.delta += p.unscannedCount;
                        }
                        return p;
                    });
                },
                incomingCurrent
            );
        return incomingOutgoingUnscanned;
    }, [inventoryDetail]);

    const unScannedArticles = useMemo(() => {
        if (!inventoryDetail?.currentStock) {
            return [];
        }
        return inventoryDetail.currentStock.filter(
            (article) =>
                !inventoryDetail.incomingStock.some((newArticle) => article.id === newArticle.id)
        );
    }, [inventoryDetail]);

    const unScannableProductsCount = useMemo(() => {
        if (!inventoryDetail?.unScannableProducts?.unScannableProducts) {
            return 0;
        }
        return inventoryDetail.unScannableProducts.unScannableProducts.reduce(
            (acc, p) => acc + p.count,
            0
        );
    }, [inventoryDetail]);

    const handleUnprocessedProductsChange = useCallback(
        (productRef: string, count: number) => {
            updateUnScannableProducts(
                {
                    id: inventoryId,
                    productRef,
                    count,
                },
                {
                    onSettled: () => {
                        fetchInventory();
                        setUpdatedInventoryDetail(!updatedInventoryDetail);
                    },
                }
            );
        },
        [
            inventoryId,
            updatedInventoryDetail,
            fetchInventory,
            setUpdatedInventoryDetail,
            updateUnScannableProducts,
        ]
    );

    const handleValidate = useCallback(
        () =>
            updateInventoryStatus(
                { id: inventoryId, status: 'validated' },
                {
                    onSuccess: () => {
                        fetchInventory();
                        setUpdatedInventoryDetail(!updatedInventoryDetail);
                    },
                }
            ),
        [
            inventoryId,
            updatedInventoryDetail,
            fetchInventory,
            setUpdatedInventoryDetail,
            updateInventoryStatus,
        ]
    );

    const handleCancel = useCallback(
        () =>
            updateInventoryStatus(
                { id: inventoryId, status: 'canceled' },
                {
                    onSuccess: () => {
                        fetchInventory();
                        setUpdatedInventoryDetail(!updatedInventoryDetail);
                    },
                }
            ),
        [
            inventoryId,
            updatedInventoryDetail,
            fetchInventory,
            setUpdatedInventoryDetail,
            updateInventoryStatus,
        ]
    );

    const handleExport = useCallback(
        () =>
            downloadDataXlsx({
                data: productRows,
                columns: {
                    id: 'ID',
                    reference: 'Référence',
                    label: 'Libellé',
                    price: "Prix d'achat",
                    theoricalCount: 'Stock théorique',
                    inventoryCount: 'Stock inventaire',
                    unscannedCount: 'Stock non scanné',
                    delta: 'Delta',
                },
                label: inventoryDetail?.label ?? 'inventaire',
            }),
        [inventoryDetail, productRows]
    );

    if (!inventoryDetail) return null;

    return (
        <Page>
            <div
                style={{
                    display: 'flex',
                    paddingLeft: 20,
                    alignItems: 'center',
                    gap: 10,
                    paddingBottom: 40,
                }}
            >
                <Typography style={fonts.pageSubtitle} flexGrow={1}>
                    {store?.label} / {inventoryDetail.label}
                </Typography>
                <Box display="flex" gap={2}>
                    {CANCEL_BUTTON_STATE[inventoryDetail.status] ? (
                        <Button
                            variant="outlined"
                            color="error"
                            sx={{ height: '36px', bgcolor: 'white' }}
                            onClick={handleCancel}
                        >
                            {CANCEL_BUTTON_STATE[inventoryDetail.status]?.text}
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            color="success"
                            sx={{ height: '36px' }}
                            onClick={handleExport}
                        >
                            Exporter
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        disabled={VALIDATE_BUTTON_STATE[inventoryDetail.status].disabled}
                        sx={{ height: '36px' }}
                        startIcon={
                            !VALIDATE_BUTTON_STATE[inventoryDetail.status].disabled && <DoneIcon />
                        }
                        onClick={handleValidate}
                    >
                        {VALIDATE_BUTTON_STATE[inventoryDetail.status].text}
                    </Button>
                </Box>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 20 }}>
                <DashboardCard
                    label="Total inventaire"
                    bigValue={`${frenchFormat(incomingStockTotal)}`}
                    orange
                />
                <DashboardCard
                    label="Total Stock"
                    bigValue={`${frenchFormat(currentStockTotal)}`}
                    orange
                />
                <DashboardCard
                    label="Total non scanné"
                    bigValue={`${frenchFormat(unScannedArticles.length)}`}
                />
                <DashboardCard
                    label="Total non scannables"
                    bigValue={`${frenchFormat(unScannableProductsCount)}`}
                />
            </div>
            <div style={{ flex: 1 }}>
                <InventoryDetails
                    label={inventoryDetail.label || ''}
                    comment={inventoryDetail.comment || ''}
                    inventoryId={inventoryDetail.id}
                    onValidate={() => fetchInventory()}
                />
                <InventoryGrid
                    handleUnprocessedProductsChange={handleUnprocessedProductsChange}
                    isLoading={isLoading}
                    productRows={productRows}
                    inventoryStatus={inventoryDetail.status}
                />
            </div>
        </Page>
    );
};
