import {
    fetchAntiGaspi,
    fetchBoutiques,
    fetchCartsDatas,
    fetchLotsData,
    fetchPromoData,
    fetchPromoProducts,
    fetchStore,
    fetchStoreArticles,
    fetchStoreCategoryProducts,
    fetchStoreCommandNumber,
    fetchStoreCpiKiosks,
    fetchStoreNewProducts,
    fetchStoreProductArticles,
    fetchStoreProducts,
    fetchStoreStock,
    fetchStores,
    fetchStoresStocks,
    fetchStoresmMetaDatas,
    fetchTodayDatas,
    updateOneMinStock,
} from '../queries/stores.queries';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

export const useStores = () => {
    return useQuery(['stores'], fetchStores, { refetchOnWindowFocus: false });
};

export const useBoutiques = () => {
    return useQuery(['stores', 'boutiques'], () => {
        return fetchBoutiques();
    });
};

export const useStore = (id: number) => {
    return useQuery(['store', id], () => {
        if (Number.isNaN(id)) return null;
        return fetchStore(id);
    });
};

export const useStoreArticles = (id: number) => {
    return useQuery(['store', id, 'articles'], () => {
        if (Number.isNaN(id)) return [];
        return fetchStoreArticles(id);
    });
};

export const useAntiGaspi = (id?: number) => {
    return useInfiniteQuery(
        ['store', id, 'antigaspi'],
        ({ pageParam = 1 }) => {
            if (Number.isNaN(id) || !id) return { data: [], hasMorePage: false };
            return fetchAntiGaspi(id, pageParam);
        },
        {
            keepPreviousData: true,
            getNextPageParam: (_lastPage, allPages) => {
                return allPages.length + 1;
            },
        }
    );
};

export const usePromoProducts = (id?: number) => {
    return useInfiniteQuery(
        ['store', id, 'promo-products'],
        ({ pageParam = 1 }) => {
            if (Number.isNaN(id) || !id) return { data: [], hasMorePage: false };
            return fetchPromoProducts(id, pageParam);
        },
        {
            keepPreviousData: true,
            getNextPageParam: (_lastPage, allPages) => {
                return allPages.length + 1;
            },
        }
    );
};

export const useStoreCategoryProducts = (id: number, cat: number) => {
    return useInfiniteQuery(
        ['store', id, 'category-products', cat],
        ({ pageParam = 1 }) => {
            if (Number.isNaN(id)) return { data: [], hasMorePage: false };
            return fetchStoreCategoryProducts(id, cat, pageParam);
        },
        {
            keepPreviousData: true,
            getNextPageParam: (_lastPage, allPages) => {
                return allPages.length + 1;
            },
        }
    );
};

export const useStoreProducts = (storeId: number) => {
    return useQuery(['store', storeId, 'products'], () => {
        if (Number.isNaN(storeId)) return [];
        return fetchStoreProducts(storeId);
    });
};

export const useStoreNewProducts = (storeId: number) => {
    return useInfiniteQuery(
        ['store', storeId, 'new-products'],
        ({ pageParam = 1 }) => {
            if (Number.isNaN(storeId)) return { data: [], hasMorePage: false };
            return fetchStoreNewProducts(storeId, pageParam);
        },
        {
            keepPreviousData: true,
            getNextPageParam: (_lastPage, allPages) => {
                return allPages.length + 1;
            },
        }
    );
};

export const useStoreStock = (id: number) => {
    return useQuery(['store', id, 'stock'], () => {
        if (Number.isNaN(id)) {
            return {
                metadata: {
                    articleCount: 0,
                    antiGaspiArticleCount: 0,
                    expiredArticleCount: 0,
                    totalStockValue: 0,
                    totalAntiGaspiStockValue: 0,
                    totalExpiredStockValue: 0,
                },
                rows: [],
            };
        }
        return fetchStoreStock(id);
    });
};

export const useStoresStocks = (ids: number[] | undefined) => {
    return useQuery(['stores', 'stocks', ids], async () => {
        if (!ids || ids.length === 0) {
            return [
                {
                    metadata: {
                        articleCount: 0,
                        antiGaspiArticleCount: 0,
                        expiredArticleCount: 0,
                        totalStockValue: 0,
                        totalAntiGaspiStockValue: 0,
                        totalExpiredStockValue: 0,
                    },
                    rows: [],
                },
            ];
        }
        return fetchStoresStocks(ids);
    });
};
export const useStoresMetadata = (ids: number[] | undefined) => {
    return useQuery(['stores', 'metadata', ids], async () => {
        if (!ids || ids.length === 0) {
            return [
                {
                    metadata: {
                        articleCount: 0,
                        antiGaspiArticleCount: 0,
                        expiredArticleCount: 0,
                        totalStockValue: 0,
                        totalAntiGaspiStockValue: 0,
                        totalExpiredStockValue: 0,
                    },
                },
            ];
        }
        return fetchStoresmMetaDatas(ids);
    });
};

export const useStoreProductArticles = (storeId: number, productId: number) => {
    return useQuery(['store', storeId, 'product', productId], () => {
        if (Number.isNaN(storeId) || Number.isNaN(productId)) return [];
        return fetchStoreProductArticles(storeId, productId);
    });
};

export const useStoreTodayData = (id: number) => {
    return useQuery(['store', id, 'todayData'], () => {
        if (Number.isNaN(id)) return null;
        return fetchTodayDatas(id);
    });
};

export const useStoreCommandNumber = (id: number) => {
    return useQuery(['store', id, 'commandNumber'], () => {
        if (Number.isNaN(id)) return 0;
        return fetchStoreCommandNumber(id);
    });
};

export const useStoreKiosks = (storeReference: string | null) => {
    return useQuery(['store', storeReference, 'kiosks'], () => {
        if (!storeReference) return [];
        return fetchStoreCpiKiosks(storeReference);
    });
};

export const useStorePromoData = (dateStart: string, dateEnd: string, ids: number[]) => {
    return useQuery(['store', 'promoData', dateStart, dateEnd, ids], () => {
        return fetchPromoData(dateStart, dateEnd, ids);
    });
};

export const useStoreLotsData = (
    page: number | null,
    pageSize: number | null,
    lot: string | null,
    ean: string | null,
    label: string | null,
    store: number | null,
    specials?: boolean
) => {
    return useQuery(['store', 'lotsData', page, pageSize, lot, ean, store, label, specials], () => {
        return fetchLotsData(page, pageSize, lot, ean, label, store, specials);
    });
};

export const usePaginateCarts = (
    storeId: number,
    page: number | null,
    pageSize: number | null,
    startDate: string | null,
    endDate: string | null,
    cartStatus: string | null
) => {
    return useQuery(
        ['store', storeId, 'carts', page, pageSize, startDate, endDate, cartStatus],
        () => {
            return fetchCartsDatas(page, pageSize, startDate, endDate, cartStatus, storeId);
        }
    );
};

export const useUpdateOneMinStock = () => useMutation(updateOneMinStock);
