import { fetchAiVideoIds, generateStreamUrl } from '../queries/ai-video.queries';

import { useQuery } from '@tanstack/react-query';

export const useVideoIds = (storeId: string) => {
    return useQuery<string[]>(['videoIds', storeId], () => fetchAiVideoIds(storeId), {
        onError: (error: unknown) => {
            console.error('Erreur lors de la récupération des videoIds:', error);
        },
        refetchOnWindowFocus: false,
    });
};

export const useAiVideoUrl = (
    storeId?: string,
    videoId?: string | null,
    socketToken?: string | null,
    streamUrl?: string | null
) => {
    return useQuery<string | null>(['ai-video-url', storeId, videoId], () =>
        generateStreamUrl(storeId, videoId ?? '', socketToken, streamUrl)
    );
};

export const useAiVideoImageUrl = (storeId: string | null, videoId: string | null) => {
    return useQuery<string>(['ai-video-image-url', storeId, videoId], () => '', {
        initialData: '',
    });
};
