import axios, { isAxiosError } from 'axios';

import { WebSocketManager } from './gozokiWebSocket';
import { setTokenCallback } from './internal/tokenCallback';

export const gozokiApi = axios.create({
    headers: {
        // Default header
        'Content-Type': 'application/json',
    },
});

export const gozokiApiWithAuth = axios.create({
    headers: {
        // Default header
        'Content-Type': 'application/json',
    },
});

export const gozokiAiApi = axios.create({
    headers: {
        // Default header
        'Content-Type': 'application/json',
    },
});

export const gozokiAiWebSocketManager = new WebSocketManager();

export const gozokiStreamWebSocketManager = new WebSocketManager();

// ********************************************************* //
//                  INITIALIZER METHODS                      //
// ********************************************************* //

export const setApiUrl = (url: string) => {
    gozokiApi.defaults.baseURL = url;
    gozokiApiWithAuth.defaults.baseURL = url;
    const urlWithTrailingSlash = url.endsWith('/') ? url : `${url}/`;
    const aiUrl = `${urlWithTrailingSlash}ai`;
    gozokiAiApi.defaults.baseURL = aiUrl;
};

export const setAiSocketUrl = (url: string) => {
    gozokiAiWebSocketManager.setBaseUrl(url);
};

export const setStreamSocketUrl = (url: string) => {
    gozokiStreamWebSocketManager.setBaseUrl(url);
};

export const attachTokenCallback = (tokenCallback: () => Promise<string | null>) => {
    setTokenCallback(tokenCallback);

    // Attach token callback to api requests
    gozokiApiWithAuth.interceptors.request.use(
        async (config) => {
            const token = await tokenCallback();

            config.headers.Authorization = `Bearer ${token}`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    gozokiAiApi.interceptors.request.use(
        async (config) => {
            const token = await tokenCallback();

            config.headers.Authorization = `Bearer ${token}`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

export const attachSocketToken = (token: string) => {
    gozokiAiWebSocketManager.attachToken(token);
};

export const attachRedirectionOnUnauthorized = (redirectCallback: () => void) => {
    gozokiApiWithAuth.interceptors.response.use(
        (response) => response,
        (err) => {
            // disconnect user if 401
            if (isAxiosError(err) && err?.response?.status === 401) {
                redirectCallback();
            }

            return Promise.reject(err);
        }
    );

    gozokiAiApi.interceptors.response.use(
        (response) => response,
        (err) => {
            // disconnect user if 401
            if (isAxiosError(err) && err?.response?.status === 401) {
                redirectCallback();
            }

            return Promise.reject(err);
        }
    );
};
