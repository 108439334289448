import { useEffect, useState } from 'react';

import { gozokiStreamWebSocketManager } from '../gozokiApi';
import { useQueryClient } from '@tanstack/react-query';

export const useLiveVideoImages = (storeId: string, videoId: string | null) => {
    const queryClient = useQueryClient();
    const [socket, setSocket] = useState<WebSocket | null>(null);

    useEffect(() => {
        let errorCount = 0;

        const ws = gozokiStreamWebSocketManager.connect('ws', {
            storeId,
            videoId: videoId ?? '',
        });
        setSocket(ws);

        const queryKey = ['ai-video-image-url', storeId, videoId];

        ws.onmessage = (event) => {
            if (event.data) {
                queryClient.setQueryData(queryKey, () => `data:image/jpeg;base64,${event.data}`);
            }
        };

        ws.onerror = (error) => {
            errorCount++;
            if (errorCount > 5) {
                console.error('Multiple webSocket errors', error);
            }
        };

        return () => {
            ws.close();
        };
    }, [queryClient, storeId, videoId]);

    return { socket };
};
