import { createContext } from 'react';
import { DailyCall } from '@daily-co/daily-js';

export interface VisioContextType {
    shouldStartCall: boolean;
    kioskId: number | null;
    storeId: number | null;
    includeVideo: boolean;
    isCallInProgress: boolean;
    callObject: DailyCall | null;
    setCallObject: (callObject: DailyCall | null) => void;
    askCall: (kioskId: number, storeId: number, includeVideo: boolean) => void;
    startCall: () => void;
    endCall: () => void;
}

export const VisioContext = createContext<VisioContextType>({
    shouldStartCall: false,
    kioskId: null,
    storeId: null,
    includeVideo: false,
    isCallInProgress: false,
    callObject: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCallObject: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    askCall: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    startCall: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    endCall: () => {},
});
