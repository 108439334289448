import '../../assets/styles/CameraFont.module.css';
import InterventionButtons from '../../components/buttons/Intervention.tsx';
import { CircularProgress } from '@mui/material';
import {
    deleteAnomaly,
    // useAiArticles,
    // useAiConsistency,
    useAiVideoUrl,
    useAnomalies,
    useCameraStores,
    useLiveAiArticles,
    useLiveAnomalies,
    useVideoIds,
    useCheckinKiosks,
} from '@gozoki/api';
import { useContext, useEffect, useState } from 'react';

import AnomalyCards from './AnomalyCards';
import { AuthContext } from '../../utils/context/auth.context';
import Box from '@mui/material/Box';
import CameraWindow from './CameraWindow';
import { StoresPicker } from '../../utils/dashboard/storesPicker';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '../../components/communication/Toast';
import Page from '../../components/Page';

const CamerasPages = () => {
    const { user } = useContext(AuthContext);
    const [centralVideoId, setCentralVideoId] = useState<string | null>(null);

    const [Toast, showToast] = useToast();
    const [isLoading, setIsLoading] = useState(true);

    const [storeId, setStoreId] = useState<string>('3');
    const { data: stores } = useCameraStores();
    const { data: videoIds } = useVideoIds(storeId);
    const { data: checkinKiosks } = useCheckinKiosks(Number(storeId));
    const checkinKioskId = checkinKiosks?.length ? checkinKiosks[0].id : null;

    const { data: anomalies } = useAnomalies(storeId);
    useLiveAnomalies(storeId);

    // const { data: articles } = useAiArticles(storeId);
    // const { data: isConsistent } = useAiConsistency(storeId);
    useLiveAiArticles(storeId);

    const { data: mainVideoUrl } = useAiVideoUrl(
        storeId,
        centralVideoId,
        user?.socketToken,
        user?.streamUrl
    );

    const { mutate: mutateDeleteAnomaly } = useMutation(
        ({ sId, aId }: { sId: string; aId: string }) => deleteAnomaly(sId, aId),
        {
            onError: () => {
                showToast({
                    severity: 'error',
                    message: "Une erreur est survenue lors de la suppression de l'anomalie",
                });
            },
        }
    );

    const handleDeleteAnomaly = (anomalyId: string) => {
        mutateDeleteAnomaly({ sId: storeId, aId: anomalyId });
    };

    const handleVideoClick = (videoId: string) => {
        if (videoId !== centralVideoId) {
            setCentralVideoId(videoId);
            setIsLoading(true);
        }
    };

    useEffect(() => {
        if (videoIds && videoIds.length > 0) {
            setCentralVideoId(videoIds[0]);
            setIsLoading(true);
        }
    }, [videoIds]);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <Page>
            <div style={{ marginLeft: '25px' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: '20px',
                        // marginTop: '10px',
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: '10px',
                            height: '100%',
                            // flex: 3,
                        }}
                    >
                        <div
                            style={{
                                color: 'darkgreen',
                                fontFamily: 'BlockBerthold_regular',
                                fontWeight: '400',
                                fontSize: '40px',
                                // display: 'flex',
                            }}
                        >
                            Caméras
                        </div>
                        <div style={{ display: 'flex', gap: '16px' }}>
                            <div style={{ flex: 1 }}>
                                <InterventionButtons
                                    checkType="CHECK-IN"
                                    kioskId={checkinKioskId}
                                    storeId={Number(storeId)}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <InterventionButtons
                                    checkType="CHECK-OUT"
                                    disabled
                                    kioskId={null} // TODO : get the kioskId of the selected kiosk
                                    storeId={Number(storeId)}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                maxHeight: '80vh',
                                maxWidth: '80vh',
                                // aspectRatio: 1.52,
                                // maxHeight: '622px',
                                // maxWidth: '951px',
                            }}
                        >
                            <div style={{ maxHeight: '80vh', maxWidth: '80vh', aspectRatio: 1.52 }}>
                                <img
                                    alt={`camera ${centralVideoId}`}
                                    src={mainVideoUrl ?? undefined}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: '8px solid #FF9900',
                                        borderRadius: '16px',
                                        aspectRatio: 1.5,
                                    }}
                                    onLoad={handleImageLoad}
                                />
                                <div>
                                    {isLoading && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                fontSize: '24px',
                                                color: '#FF9900',
                                            }}
                                        >
                                            <CircularProgress size="70px" color="success" />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '21px',
                                    // maxHeight: '80vh',
                                    // maxWidth: '100vh',
                                    overflowX: 'auto',
                                }}
                            >
                                {videoIds?.map((videoId) => (
                                    <CameraWindow
                                        key={videoId}
                                        videoId={videoId}
                                        storeId={storeId}
                                        onClick={() => handleVideoClick(videoId)}
                                        isSelected={centralVideoId === videoId}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: '20px',
                            // minHeight: '980px',
                            // maxHeight: '980px',
                            // flex: 1,
                        }}
                    >
                        <Toast />
                        <Box
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            py={2}
                        >
                            <Box sx={{ width: 500 }}>
                                <StoresPicker
                                    setUniqueStore={(s) => setStoreId(s.toString())}
                                    usedStores={stores?.map((s) => Number.parseInt(s.id, 10))}
                                />
                            </Box>
                        </Box>
                        <div style={{ height: '80%', overflowY: 'auto' }}>
                            <AnomalyCards anomalies={anomalies} onDelete={handleDeleteAnomaly} />
                        </div>
                        <div style={{ flexGrow: 1 }} />
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default CamerasPages;
