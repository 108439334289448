import { useAiVideoImageUrl, useLiveVideoImages } from '@gozoki/api';

import { Button } from '@mui/material';

interface CameraWindowProps {
    videoId: string;
    storeId: string;
    width?: string;
    height?: string;
    onClick?: () => void;
    isSelected?: boolean;
}

const CameraWindow = ({
    videoId,
    storeId,
    width = '172px',
    height = '115px',
    onClick,
    isSelected = false,
}: CameraWindowProps) => {
    const { data: imageUrl } = useAiVideoImageUrl(storeId, videoId);
    useLiveVideoImages(storeId, videoId);

    return (
        <div>
            <Button
                onClick={onClick}
                disabled={!onClick}
                variant="outlined"
                disableRipple
                style={{
                    width,
                    height,
                    flexShrink: 0,
                    boxSizing: 'border-box',
                }}
            >
                <img
                    alt={`camera ${videoId}`}
                    src={imageUrl}
                    style={{
                        width,
                        height,
                        flexShrink: 0,
                        border: isSelected ? '4px solid orange' : undefined,
                    }}
                />
            </Button>
        </div>
    );
};

export default CameraWindow;
