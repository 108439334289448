import { useDaily, useLocalParticipant, DailyVideo, DailyAudio } from '@daily-co/daily-react';
import { useContext } from 'react';
import { Button, Box } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { VisioContext } from '../../utils/context/visio.context';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const VideoCall = ({ leaveCall, cameraOn }: { leaveCall: () => void; cameraOn: boolean }) => {
    const daily = useDaily();
    const localParticipant = useLocalParticipant();
    const { isCallInProgress } = useContext(VisioContext);

    return (
        <div>
            {isCallInProgress && localParticipant && (
                <>
                    {cameraOn ? (
                        <DailyVideo
                            sessionId={localParticipant.session_id}
                            type="video"
                            automirror
                            fit="contain"
                            style={{ width: '500px', height: '500px' }}
                        />
                    ) : (
                        <AccountBoxIcon sx={{ fontSize: '500px' }} />
                    )}

                    <DailyAudio />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 2,
                            mt: 2,
                        }}
                    >
                        <Button
                            onClick={() => {
                                daily?.leave();
                                daily?.destroy();
                                leaveCall();
                            }}
                            variant="contained"
                            sx={{
                                'backgroundColor': 'red',
                                '&:hover': { backgroundColor: 'darkred' },
                                '&:disabled': { backgroundColor: 'red', opacity: '30%' },
                            }}
                        >
                            <LogoutIcon />
                            Quitter
                        </Button>
                    </Box>
                </>
            )}
        </div>
    );
};

export default VideoCall;
