import type {
    AccountingKpis,
    DailyDashboardKpi,
    DailyStockKpi,
    DayOfWeek,
    HoursData,
    TimeSpentData,
} from '@gozoki/api-types';
import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import {
    fetchAccountingKpis,
    fetchAccountingWarnings,
    fetchAllDailysKpis,
    fetchAllStockKpis,
    fetchHoursData,
    fetchTimeSpentData,
} from '../queries/kpi.queries';

export const useStoresKpis = (): UseQueryResult<DailyDashboardKpi[], unknown> => {
    // if storesIDs is undefined, we query all stores
    return useQuery(['get-kpi'], () => {
        return fetchAllDailysKpis();
    });
};

export const useStockKpis = (): UseQueryResult<DailyStockKpi[], unknown> => {
    return useQuery(['get-stock-kpi'], () => {
        return fetchAllStockKpis();
    });
};

export const useAccountingKpis = (date: string): UseQueryResult<AccountingKpis, unknown> => {
    return useQuery(['get-accounting-kpis', date], () => {
        return fetchAccountingKpis(date);
    });
};

export const useAccountingWarnings = (): UseQueryResult<
    { date: string; difference: number }[],
    unknown
> => {
    return useQuery(['get-accounting-warnings'], () => {
        return fetchAccountingWarnings();
    });
};

export const useHoursData = (
    days: DayOfWeek[],
    storeIds: number[],
    plage: 'AM' | 'PM' | 'DAY',
    dateStart: Date,
    dateEnd: Date
): UseQueryResult<HoursData[], unknown> => {
    const plageKey = plage || 'DAY';

    return useQuery(['get-hours-data', days, storeIds, plageKey, dateStart, dateEnd], () => {
        return fetchHoursData(days, storeIds, plage, dateStart, dateEnd);
    });
};

export const useTimeSpentDatas = (
    days: DayOfWeek[],
    storeIds: number[],
    plage: 'AM' | 'PM' | 'DAY',
    dateStart: Date,
    dateEnd: Date
): UseQueryResult<TimeSpentData[], unknown> => {
    const plageKey = plage || 'day';
    return useQuery(['get-time-spent-data', days, storeIds, plageKey, dateStart, dateEnd], () => {
        return fetchTimeSpentData(days, storeIds, plage, dateEnd, dateStart);
    });
};
